
/* eslint-disable */
import Img1 from '../Static/Pubs/ArtMade/1-min.jpg'
import Img2 from '../Static/Pubs/ArtMade/2-min.jpg'
import Img3 from '../Static/Pubs/ArtMade/3-min.jpg'
import Img4 from '../Static/Pubs/ArtMade/4-min.jpg'
import Img5 from '../Static/Pubs/ArtMade/5-min.jpg'
import Img6 from '../Static/Pubs/ArtMade/6-min.jpg'
import Img7 from '../Static/Pubs/ArtMade/7-min.jpg'
import Img8 from '../Static/Pubs/ArtMade/8-min.jpg'
import Img9 from '../Static/Pubs/ArtMade/9-min.jpg'
import Img10 from '../Static/Pubs/ArtMade/10-min.jpg'
import Img11 from '../Static/Pubs/ArtMade/11-min.jpg'

const ListImages = [
    {
        id: 1,
        imgSrc: Img1
    },
    {
        id: 2,
        imgSrc: Img2
    },
    {
        id: 3,
        imgSrc: Img3
    },
    {
        id: 4,
        imgSrc: Img4
    },
    {
        id: 5,
        imgSrc: Img5
    },
    {
        id: 6,
        imgSrc: Img6
    },
    {
        id: 7,
        imgSrc: Img7
    },
    {
        id: 8,
        imgSrc: Img8
    },
    {
        id: 9,
        imgSrc: Img9
    },
    {
        id: 10,
        imgSrc: Img10
    },
    {
        id: 11,
        imgSrc: Img11
    }
]

export default ListImages;
