import Navigation from './Navigation/navigation';
import DisplayPhotos from './Display-Photos/display-photos'
import SocialFooter from './Social-footer/social-footer'

import './home-page.css';

function HomePage() {
  return (
    <div className="main">
      <Navigation />
      <div className='portfolio'>
        <DisplayPhotos/>
      </div>
      <SocialFooter/>
    </div>
  );
}

export default HomePage;
