import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';

import './social-footer.css'

const SocialFooter = () => {
  return (
    <footer className="social-footer">
      <div id="contact" className="social-icons">
        <a href="https://www.instagram.com/_joao_m0ta_/" target="_blank" className='social-icons-insta' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="mailto:j0a0m0taphotography@gmail.com" className='social-icons-mail'>
          <FontAwesomeIcon icon={faTelegramPlane} />
        </a>
      </div>
    </footer>
  );
};

export default SocialFooter;