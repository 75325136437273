/* eslint-disable */
import Img1 from '../Static/media/1-min.jpg'
import Img2 from '../Static/media/2-min.jpg'
import Img3 from '../Static/media/3-min.jpg'
import Img4 from '../Static/media/4-min.jpg'
import Img5 from '../Static/media/5-min.jpg'
import Img6 from '../Static/media/6-min.jpg'
import Img7 from '../Static/media/7-min.jpg'
import Img8 from '../Static/media/8-min.jpg'
import Img9 from '../Static/media/9-min.jpg'
import Img10 from '../Static/media/10-min.jpg'
import Img11 from '../Static/media/11-min.jpg'
import Img12 from '../Static/media/12-min.jpg'
import Img13 from '../Static/media/13-min.jpg'
import Img14 from '../Static/media/14-min.jpg'
import Img15 from '../Static/media/15-min.jpg'
import Img16 from '../Static/media/16-min.jpg'
import Img17 from '../Static/media/17-min.jpg'
import Img18 from '../Static/media/18-min.jpg'
import Img19 from '../Static/media/19-min.jpg'
import Img20 from '../Static/media/20-min.jpg'
import Img21 from '../Static/media/21-min.jpg'
import Img22 from '../Static/media/22-min.jpg'
import Img23 from '../Static/media/23-min.jpg'
import Img24 from '../Static/media/24-min.jpg'

const ListImages = [
    {
        id: 1,
        imgSrc: Img1
    },
    {
        id: 2,
        imgSrc: Img2
    },
    {
        id: 3,
        imgSrc: Img3
    },
    {
        id: 4,
        imgSrc: Img4
    },
    {
        id: 5,
        imgSrc: Img5
    },
    {
        id: 6,
        imgSrc: Img6
    },
    {
        id: 7,
        imgSrc: Img7
    },
    {
        id: 8,
        imgSrc: Img8
    },
    {
        id: 9,
        imgSrc: Img9
    },
    {
        id: 10,
        imgSrc: Img10
    },
    {
        id: 11,
        imgSrc: Img11
    },
    {
        id: 12,
        imgSrc: Img12
    },
    {
        id: 13,
        imgSrc: Img13
    },
    {
        id: 14,
        imgSrc: Img14
    },
    {
        id: 15,
        imgSrc: Img15
    },
    {
        id: 16,
        imgSrc: Img16
    },
    {
        id: 17,
        imgSrc: Img17
    },
    {
        id: 18,
        imgSrc: Img18
    },
    {
        id: 19,
        imgSrc: Img19
    },
    {
        id: 20,
        imgSrc: Img20
    },
    {
        id: 21,
        imgSrc: Img21
    },
    {
        id: 22,
        imgSrc: Img22
    },
    {
        id: 23,
        imgSrc: Img23
    },
    {
        id: 24,
        imgSrc: Img24
    }
]

export default ListImages;
