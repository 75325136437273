import './navigation.css';
import React from 'react';

/* eslint-disable */


function scrollToSection(sectionId) {
  if (section) {
    var section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

function Navigation() {


  return (
    <header className="navigation">
      <div className='navigation-header'>
        <div className='navigation-header-titles'>
          <a className='navigation-header-titles-title' href='#' onClick={scrollToSection()}>Main Portfolio</a>
          <a className='navigation-header-titles-title' href='#events' onClick={scrollToSection('events')}>Events</a>
          <a className='navigation-header-titles-title' href='#pub' onClick={scrollToSection('pub')}>Publicity</a>
        </div>
        <a className='navigation-header-logo'>João Mota</a>
        <div className='navigation-list'>
          <a href='#contact' onClick={scrollToSection('contact')}>Contact</a>
        </div>
      </div>
    </header>
  );
}

export default Navigation;
