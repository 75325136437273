import React, { useState } from 'react';
import './portfolio.css';

/* eslint-disable */
function Portfolio({images}) {

    const [expandedImage, setExpandedImage] = useState(null);

    const expandImage = (imageSrc) => {
        setExpandedImage(imageSrc);
    };

    const closeExpandedImage = () => {
        setExpandedImage(null);
    };

    return (
        <div className="portfolio-gallery">
            {images.map((imageUrl, index) => (
                <img key={index} src={imageUrl.imgSrc} className="gallery-image" 
                loading="lazy"  onLoad={(e) => e.currentTarget.classList.add('loaded')} onClick={() => expandImage(imageUrl.imgSrc)}/>
            ))}

            {expandedImage && (
                <div className="expanded-img-container" onClick={closeExpandedImage}>
                    <img src={expandedImage} className="expanded-img" alt="Expanded" />
                </div>
            )}
        </div>
    );
}

export default Portfolio;
