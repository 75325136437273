
/* eslint-disable */
import Img1 from '../Static/media/Events/Filipe/Filipe-1.jpg'
import Img2 from '../Static/media/Events/Filipe/Filipe-2.jpg'
import Img3 from '../Static/media/Events/Filipe/Filipe-3.jpg'
import Img4 from '../Static/media/Events/Filipe/Filipe-4.jpg'
import Img5 from '../Static/media/Events/Filipe/Filipe-5.jpg'
import Img6 from '../Static/media/Events/Filipe/Filipe-6.jpg'
import Img7 from '../Static/media/Events/Filipe/Filipe-7.jpg'
import Img8 from '../Static/media/Events/Filipe/Filipe-8.jpg'
import Img9 from '../Static/media/Events/Filipe/Filipe-9.jpg'
import Img10 from '../Static/media/Events/Rally24/rally-1.jpg'
import Img11 from '../Static/media/Events/Rally24/rally-2.jpg'
import Img12 from '../Static/media/Events/Rally24/rally-3.jpg'
import Img13 from '../Static/media/Events/Rally24/rally-4.jpg'
import Img14 from '../Static/media/Events/Rally24/rally-5.jpg'

const ListImages = [
    {
        id: 1,
        imgSrc: Img1
    },
    {
        id: 2,
        imgSrc: Img2
    },
    {
        id: 3,
        imgSrc: Img3
    },
    {
        id: 4,
        imgSrc: Img4
    },
    {
        id: 5,
        imgSrc: Img5
    },
    {
        id: 6,
        imgSrc: Img6
    },
    {
        id: 7,
        imgSrc: Img7
    },
    {
        id: 8,
        imgSrc: Img8
    },
    {
        id: 9,
        imgSrc: Img9
    },
    {
        id: 10,
        imgSrc: Img10
    },
    {
        id: 11,
        imgSrc: Img11
    },
    {
        id: 12,
        imgSrc: Img12
    },
    {
        id: 13,
        imgSrc: Img13
    },
    {
        id: 14,
        imgSrc: Img14
    }
]

export default ListImages;
