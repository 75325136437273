import React from 'react';
import Portfolio from '../Portfolio/portfolio'

import artMade from '../Static/art-made'
import HomePage from '../Static/home-page-images';
import EventsMedia from '../Static/events'
/* eslint-disable */

import './display-photos.css'

function DisplayPhotos({image}) {
    return (
        <>
            <Portfolio id="home" images={HomePage} />

            <h2 className='title' id="events">Events</h2>

            <Portfolio id="home" images={EventsMedia} />

            <h2 className='title' id="pub">Publicity</h2>
            
            <Portfolio images={artMade} />
        </>
    );
}

export default DisplayPhotos;
